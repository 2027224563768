import { Box, Typography } from "@mui/material";
// import CardLayout from "../../components/CardLayout";
import PricingCard from "../../Packages/PricingCard";
import "../NewPlan/style.css";
import Header from "../../Packages/Header";
import Footer from "../../Packages/Footer";
import bg from "../../assets/Images/bg.png";
import "../../style/Typography.css";


function Plan({ selectedCountry, setSelectedCountry }) {
  return (
    <div>
      <div
        style={{
          backgroundImage: `url(${bg})`,
          backgroundSize: "cover", 
          width: "100%", 
          height: "410px", 
        }}
      >
         <Header selectedCountry={selectedCountry} setSelectedCountry={setSelectedCountry}/>
        <Typography className="headingOne">Pricing</Typography>
        <Typography className="headingTwo">
          Affordable pricing for all type of users. Sign-up for early access.
        </Typography>
        
      </div>
      <PricingCard selectedCountry={selectedCountry}/>
      <Footer />
    </div>
  );
}

export default Plan;
