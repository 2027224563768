import React from "react";
import Frame from "../../assets/Images/Frame.png";
import Monere from "../../assets/Images/Monere.png";
import cross from "../../assets/Images/cross.png";
import { useNavigate } from "react-router-dom";
import CountryMenu from "../CountryMenu";
import PrimaryBtn from "../PrimaryBtn";

function Header({ selectedCountry, setSelectedCountry }) {
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [elevation, setElevation] = React.useState(0);
  const [bgColor, setBgColor] = React.useState("transparent");
  const [menuOpen, setMenuOpen] = React.useState(false);

  const handleClick = () => {
    setMenuOpen(!menuOpen);
  }

  const handleScroll = () => {
    const isScrolled = window.scrollY > 0;
    setElevation(isScrolled ? 4 : 0);
    setBgColor(isScrolled ? "#17232A" : "transparent");
  };

  React.useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  function goToHome() {
    window.open("https://www.futuredata.info/", "_blank");
  }
  const navigate = useNavigate();

  function goToLogin() {
    navigate("/login");
  }

  function goToUnsubscribe() {
    navigate("/unsubscribe");
  }

  return (
   
      <div>
        <div className="flex flex-row justify-between items-center h-14 px-6 fixed top-0 w-full z-50" style={{ backgroundColor: bgColor }}>
          <div
            onClick={goToHome}
            className="flex justify-center items-center"
          >
            <img src={Frame} alt="logo1" width="48" height="43" />
            <img
              src={Monere}
              alt="logo2"
              className="ml-1.5"
              width="126"
              height="27"
            />
          </div>
          <ul>
            <list className="hover:bg-greyTwo py-2 px-4 mx-3 text-white hover:text-primeGreen font-pop600 text-base rounded-3xl"><button>Home</button></list>
            <list className="hover:bg-greyTwo py-2 px-4 mx-3 text-white hover:text-primeGreen font-pop600 text-base rounded-3xl"><button>What we are solving</button></list>
            <list className="hover:bg-greyTwo py-2 px-4 mx-3 text-white hover:text-primeGreen font-pop600 text-base rounded-3xl"><button>Products</button></list>
            <list className="hover:bg-greyTwo py-2 px-4 mx-3 text-white hover:text-primeGreen font-pop600 text-base rounded-3xl"><button>Partners</button></list>
            {/* <list className="hover:bg-greyTwo py-2 px-4 mx-3 text-white hover:text-primeGreen font-pop600 text-base rounded-3xl"><button>Pricing</button></list> */}
            <list className="hover:bg-greyTwo py-2 px-4 mx-3 text-white hover:text-primeGreen font-pop600 text-base rounded-3xl"><button>Resources</button></list>
          </ul>

          <div className="flex">
            <CountryMenu selectedCountry={selectedCountry} setSelectedCountry={setSelectedCountry} />
            <button className="bg-primeBlue w-32 h-10 text-white ml-2 font-pop600 text-sm rounded-lg" onClick={handleClick}>My Monere</button>
            {menuOpen && (
            <ul
            className="absolute ml-2 mt-10 w-[8rem] h-[5rem] bg-darkTeal rounded-lg"
          >
            <li
              onClick={goToLogin}
              sx={{
                borderBottom: "2px solid #1C5988",
                paddingBottom: 2,
              }}
              className="border-b-2 border-primeBlue text-sm font-pop400 text-white px-5 py-2 cursor-pointer"
            >
              Login
            </li>
            <li onClick={goToUnsubscribe}  className="text-sm font-pop400 text-white px-5 py-2 cursor-pointer">
              Unsubscribe
            </li>
          </ul>
          )}
          </div>
        </div>
          
      </div>
   
  );
}

export default Header;
