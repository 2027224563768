import React, { useEffect, useState } from "react";
import CardLayout from "../../Packages/CardLayout";
import { useNavigate } from "react-router-dom";
import Data_us from "../../Pricing_Plan/Pricing_Plan_US.json";
import Data_in from "../../Pricing_Plan/Pricing_Plan_In.json";

function PricingCard({ selectedCountry }) {
  const [data, setData] = useState(null);

  const navigate = useNavigate();

  function validate() {
    navigate("/ValidateEmail");
  }
  function goToSales() {
    navigate("/Sales");
  }

  useEffect(() => {
    let dataFile;
    if (selectedCountry === 'india') {
      dataFile = Data_in;
    } else {
      dataFile = Data_us;
    }
    const start_index = 1;
    const trim_data = dataFile.slice(start_index);
    setData(trim_data);
  }, [selectedCountry]);

  const extractFeatures = (features) => {
    return Object.keys(features).map(
      (key) => `${features[key].display_name} ~ ${features[key].count_feature}`
    );
  };

  return (
    <div>
      <div className="flex items-center justify-center h-auto mb-5 -mt-4">
        {data?.map((plan, index) => {
          const cardProps = {
            key: index,
            recommended: plan.PricingPlan.active,
            planName: plan.PricingPlan.display_plan_name,
            price: `${plan.icon}${plan.PricingPlan.plan_price} ${plan.currency}`,
            limit: "Per month, billed annually",
            tagline: "",
            list: extractFeatures(plan.features),
            primaryBtn: "Subscribe",
            primaryBtnFun: validate,
          };

          if (plan.PricingPlan.display_plan_name === "Basic") {
            cardProps.tagline = "Includes 7 day free trial, plus:";
          }
          if (plan.PricingPlan.display_plan_name === "Professional") {
            cardProps.tagline = "All the benifits of Basic, plus:";
          }
          if (plan.PricingPlan.display_plan_name === "Enterprise") {
            cardProps.price = "Tailored";
            cardProps.limit = "Contact sales for estimates";
            cardProps.tagline = "All the benifits of Professional, plus:";
            cardProps.secondaryBtn = "Contact Sales";
            cardProps.secondaryBtnFun = goToSales;
            cardProps.extrabtn = true;
          }

          return (
            <div key={index} className="flex flex-col items-center">
              <CardLayout {...cardProps} />
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default PricingCard;
